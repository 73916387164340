import React from "react";

import Seo from "../../components/info-site/Seo";
import Layout from "../../layouts/en";
import WildCaseStudy from "../../components/info-site/CaseStudyWild";
import GetStarted from "../../components/info-site/GetStarted"

const WildCase = props => {
    return (
        <Layout light location={props.location}>
            <Seo titleId="wildCaseTitle" descriptionId="wildCaseDescription" />
            <WildCaseStudy
              title="wildCaseTitle"
              subTitle="wildCaseSubTitle"
            />

            <GetStarted light />
        </Layout>
    );
};

export default WildCase;