import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import styles from "../../styles/info-site/CaseStudyWild.module.css";

// import wildImg from "../../assets/wild-deodorants.jpeg"
import wildImg from "../../assets/wearewild-deodorants-02.avif"
import simplycookImg from "../../assets/simplycook.jpeg"
import tyvisoLogo from "../../assets/simple-logo.png"

import TestimonoalImgOne from "../../assets/flora_picture.jpeg"
import TestimonoalImgTwo from "../../assets/pippa_picture.jpeg"
import Cta from '../styleguide/Cta';

const testimonials = [
  {
    id: 'wild',
    img: TestimonoalImgOne,
    alt: '',
    text: '"Having rigorously tested the impact of GWP on conversion rate, we were ecstatic to confirm the hypothesis that offering ‘Free Gifts’ to our customers before checkout would increase our basket completion rate. \n\nLaunching GWP with Tyviso has unlocked a brand new rev-gen channel for the business with huge potential to scale. We’ll now be looking to roll GWP out across all our markets. \n\nWe have also seen a very positive response from our customers who appreciate the ‘Free Gift’, contributing to an enhanced customer experience when shopping with us."',
    author: 'Flora Jetha, Senior Partnerships Executive, Wild'
  },
  {
    id: 'simplycook',
    img: TestimonoalImgTwo,
    alt: '',
    text: '"We have been extremely pleased with how the campaign has performed for us so far. \n\nWild sends us thousands of highly qualified clicks through their GWP execution, which we’ve been able to convert to hundreds of new customers at a cost-effective CPA. We hope to continue working with them closely to drive even more new customers and revenue."',
    author: 'Pippa Bryant, Affiliate & Partnerships Manager, SimplyCook'
  }
]

function CaseStudyWild({ title, subTitle }) {
  return (
    <div className={styles.CaseStudy}>
      <h2 className={styles.CaseStudyTitle}>
        <FormattedMessage id={title} />
      </h2>

      <div className={styles.CaseStudyFeaturedWrapper}>
        <img
          className={styles.CaseStudyFeaturedImg}
          src={wildImg}
          alt="wearewild deodorants"
        />
        <div className={styles.CaseStudyFeaturedContent}>
          <h3 className={styles.CaseStudySubTitle}>
            <FormattedMessage id={subTitle} />
          </h3>
          <p className={styles.CaseStudyText}>
            <FormattedMessage id="wildCaseFeaturedTextOne" />
          </p>
          <p className={styles.CaseStudyText}>
            <FormattedHTMLMessage id="wildCaseFeaturedTextTwo" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="wildCaseObjectiveTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="wildCaseObjectiveDescription" />
          </p>
          <ol>
            <li>
              <FormattedMessage id="wildCaseObjectiveItemOne" />
            </li>
            <li>
              <FormattedMessage id="wildCaseObjectiveItemTwo" />
            </li>
            <li>
              <FormattedMessage id="wildCaseObjectiveItemThree" />
            </li>
          </ol>
        </div>
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="wildCaseApproachTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="wildCaseApproachItemOne" />
          </p>
          <p>
            <FormattedMessage id="wildCaseApproachItemTwo" />
          </p>
          <p>
            <FormattedMessage id="wildCaseApproachItemThree" />
          </p>
          <p>
            <FormattedMessage id="wildCaseApproachItemFour" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudySimply}>
        <img src={simplycookImg} alt="" />
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="wildCaseResultsTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="wildCaseResultsItemOne" />
          </p>
          <p>
            <FormattedMessage id="wildCaseResultsItemTwo" />
          </p>
          <p>
            <FormattedMessage id="wildCaseResultsItemThree" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudyTestimonialWrapper}>
        {
          testimonials.map(item => (
            <div className={styles.CaseStudyTestimonial}>
              <div className={styles.CaseStudyTestimonialImage}>
                <img
                  src={item.img}
                  alt={item.alt}
                />
              </div>
              <div className={styles.CaseStudyTestimonialContent}>
                <p>{item.text}</p>
                <h3>{item.author}</h3>
              </div>
          </div>
          ))
        }
      </div>

      <div className={styles.CaseStudyLaunch}>
        <h3><FormattedMessage id="perksCaseLaunchTitle" /></h3>
        <p><FormattedMessage id="perksCaseLaunchDesc" /></p>

        <Cta className={styles.Cta} link="/contact" text="contactUs" />
      </div>
    </div>
  );
};

export default CaseStudyWild;